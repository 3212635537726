var $_CONFIG = $_CONFIG || {};
$_CONFIG.useSvgSprite = false;
$_CONFIG.scripts = $_CONFIG.scripts || [];
$_CONFIG.domain = 'https://rabona.devv2.space';
$_CONFIG.siteDomain = 'https://m.rabona.com';
$_CONFIG.cdn = '/joxi';

$_CONFIG.apiVersion = 'v2';

$_CONFIG.sport = true;
$_CONFIG.sportVersion = 3;
$_CONFIG.sportId = 'rabona';
$_CONFIG.sportFixedBottom = 20;
$_CONFIG.mobile = true;

$_CONFIG.pwa = true;
$_CONFIG.appName = 'Rabona';
$_CONFIG.themeColor = '#1E2737';

$_CONFIG.anjouanId = "15a27c1e-82be-4069-82a9-340adfeab8a1";

$_CONFIG.siteName = 'rabona';
$_CONFIG.redirectToNotFound = false;

$_CONFIG.libphonenumber = true;

$_CONFIG.hostname = 'm.rabona.com';

$_CONFIG.hotjar = {
  customHeatMapEnabled: true,
};

$_CONFIG.googleAnalytics = {
  appId: 'UA-151907223-1',
  trackAs: 'rabona',
};

$_CONFIG.sentry = {
  enabled: true,
  key: 'd68035ef32452df6f55736934fa0f737',
  id: '152',
  project: 'm_rabona',
};

$_CONFIG.symplify = true;
//- ПЛЕЙСИ ДЛЯ БАНЕРІВ І СЛАЙДЕРІВ
//- в шаблоні вказувати саме об'єкт конфіга.

$_CONFIG.places = {
  banner: {
    login1: `'login'`,
    home1: `'sport_main_banner'`,
    home2: `'sport_promotion'`,
    home3: `'live_promotion'`,
    home4: `'casino_promotion'`,
    home5: `'collection_main_banner1'`,
    home6: `'more_bonuses'`,
    home7: `'home_aviator'`,
    home8: `'homepage_banner_promos'`,
    home9: `'homepage_banner_crab'`,
    season_sport_tournament: `'season_sport_tournament'`,
    home_ao: `'home_page_ao_banner'`,
    easter_main: `'easter_banner'`,
  },
  slider: {
    gamehall1: `'game_hall'`,
    sport1: `'sport_page'`,
    home1: `'main_slider'`,
  },
  promo: {
    registration1: `'welcome_bonus'`,
    home1: `'first_deposit'`,
    home2: `'main_page_bonus'`,
    gamehall1: `'first_deposit'`,
    sport1: `'first_deposit'`,
  },
  icons: {
    footer1: `'footer_pay'`,
  },
};

$_CONFIG.sportBanners = {
  topCenter: {
    iframe: `/sport-banners-slider/`,
    options: {
      pages: ['prelive'],
      height: '190px',
    },
  },
};

if (typeof window !== 'undefined') {
  window.$_REGISTRATION = {};
  window.$_REGISTRATION.promotions = {
    category: 'welcome_bonus',
    active: 0,
  };
}

export { $_CONFIG };
